<template>
    <div class="echarts">
        <div id="" style="width: 100%; height: 60px" ref="echarts"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: 'MyEcharts',
    props: ["seatRateSum", 'itemValue'],
    mounted() {
        //  // console.log("this.seatRateSum", this.seatRateSum);
        //  // console.log("this.itemValue", this.itemValue);
        let chartColumn = echarts.init(this.$refs.echarts);
        chartColumn.setOption(
            {
                // tooltip: {
                //     trigger: 'item'
                // },

                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        color: ['#0066CC', '#D6EBFF'],
                        radius: ['40%', '80%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            // borderRadius: 10,
                            borderColor: '#fff',
                            // borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        // emphasis: {
                        //     label: {
                        //         show: true,
                        //         fontSize: 40,
                        //         fontWeight: 'bold'
                        //     }
                        // },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.seatRateSum - this.itemValue, name: '总个数' },
                            { value: this.itemValue, name: '个数' },
                        ]
                    }
                ]
            }
        )
    },
    methods: {

    }
}
</script>


<style scoped lang="scss"></style>
