<template>
  <div class="content">
    <!--<div class="talk" v-if="name == 'admin'">-->
    <!--上游报备话术:-->
    <!--<br/>-->

    <!--<a href="http://106.14.0.130:9005/shangyoujiekouYD1.pdf"-->
    <!--&gt;移动1报备话术(暂无)</a-->
    <!--&gt;-->
    <!--<a href="http://106.14.0.130:9005/shangyoujiekouYD4.pdf">移动4报备话术</a>-->
    <!--<a href="http://106.14.0.130:9005/shangyoujiekouLT1.pdf">联通1报备话术</a>-->
    <!--</div>-->

    <div class="tit">
      <h1 style="font-weight: bold">账号信息</h1>
      <div class="dep">
        <!-- <span style="font-weight: bold;">账号权限 <em>「{{ realname }}」</em></span> -->
        <span style="font-size: 16px"
          ><span style="font-weight: bold; font-size: 20px">账号权限:</span>
          {{ realname }}
        </span>
      </div>
    </div>
    <div class="down"></div>
    <div v-if="id == 1">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="tabTop">
        <el-tab-pane label="数据统计" name="dataT">
          <div style="padding: 10px; box-sizing: border-box">
            <div class="adminBox">
              <div class="item-box" v-for="(item, i) in adminList" :key="i">
                <div class="box-top">
                  <!-- <el-tag :type="item.type">{{ item.titleTag }}</el-tag> -->
                  <!-- <el-tag :type="item.type">{{ item.titleTag }}</el-tag> -->
                  <div class="adminBoxImg" :class="item.className"></div>
                  <div class="title">{{ item.title }}</div>
                </div>
                <div class="contetn-box">
                  <div class="title">
                    <div class="titleBox">
                      <p>{{ dataForm[item.data] || 0 }}</p>
                      <p class="annex" v-if="item.title == '企业余额'">
                        企业套餐余额: {{ qy_seatMoney }}
                      </p>
                      <p
                        class="annex"
                        v-if="item.title == '当前坐席'"
                        @click="test_stop_count()"
                      >
                        报停和测试坐席数: {{ seatCount_stop }}
                      </p>
                    </div>
                  </div>
                  <!-- <el-image style="width: 30px;height: 30px;" :src="item.src" fit="fill"></el-image> -->
                </div>
              </div>

              <!-- <div class="item-box" v-for="(item, i) in oldSumTable" :key="i">
                              <div class="box-top">
                                <div class="title">{{ item.title }}</div>
                                <el-tag :type="item.type">{{ item.titleTag }}</el-tag>
                              </div>
                              <div class="contetn-box">
                                <div class="title">
                                  {{ oldSumForm[item.data] || 0 }}
                                  <br>
                                </div>
                                <el-image style="width: 30px;" :src="item.src" fit="fill"></el-image>
                              </div>
                            </div> -->
            </div>
            <div class="adminBox" style="display: block">
              <div class="rollBox">
                <div class="item-box" v-for="(item, i) in lineTable" :key="i">
                  <div class="box-top">
                    <div class="adminBoxImg ico06"></div>
                    <div class="title">{{ item.title }}</div>
                    <!-- <el-tag :type="item.type">{{ item.titleTag }}</el-tag> -->
                  </div>
                  <div class="contetn-box">
                    <div class="title" style="width: 100%">
                      <div style="display: flex; width: 100%">
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            flex: 1;
                          "
                        >
                          <span>{{ lineForm[item.data] || 0 }}</span>
                        </div>
                        <span style="font-size: 12px; margin-top: 6px"
                          >(分钟)</span
                        >
                      </div>
                      <!-- <br> -->
                    </div>
                    <!-- <el-image style="width: 30px;height: 30px;" :src="item.src" fit="fill"></el-image> -->
                  </div>
                  <div>
                    <span>{{ lineForm2[item.data] || 0 }}</span>
                    <span style="font-size: 12px; margin-top: 6px"
                      >(落地余额)</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- <el-radio-group v-model="active" style="margin-bottom: 30px;" @tab-click="">
                          <el-radio-button label="1">本月</el-radio-button>
                          <el-radio-button label="2">今日</el-radio-button>
                        </el-radio-group> -->
          </div>
          <!-- <el-radio-group v-model="active" style="margin-bottom: 30px;" @tab-click="">
                      <el-radio-button label="1">本月</el-radio-button>
                      <el-radio-button label="2">今日</el-radio-button>
                    </el-radio-group> -->
        </el-tab-pane>
        <el-tab-pane label="套餐统计" name="package">
          <div class="adminBox">
            <div
              class="item-box"
              style="padding-bottom: 10px; width: 19%"
              v-for="(item, i) in seatRateArr"
              :key="i"
              @click="openPackageDetails(item)"
            >
              <div
                style="display: flex; align-items: center; padding-bottom: 6px"
              >
                <div class="box-top" style="flex: 2">
                  <h2
                    class="title"
                    style="
                      font-weight: bold;
                      font-family: 微软雅黑;
                      font-size: 28px;
                    "
                  >
                    <span>{{ item.seat_rate }}</span
                    >套餐
                  </h2>
                  <!-- <el-tag>个</el-tag> -->
                  <div
                    class="title"
                    style="
                      font-size: 14px;
                      font-family: 微软雅黑;
                      font-weight: bold;
                      margin-top: 8px;
                    "
                  >
                    <span>已开通{{ item.count }}个</span>
                    <!-- <br> -->
                  </div>
                </div>
                <div
                  class="contetn-box"
                  style="flex: 1"
                  v-if="activeName == 'package'"
                >
                  <!-- <el-image style="width: 30px;height: 30px;" :src="rateSrc" fit="fill"></el-image> -->
                  <MyEcharts
                    :seatRateSum="seatRateSum"
                    :itemValue="item.count"
                  ></MyEcharts>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="套餐金额" name="tcSum">
          <div style="padding: 10px; box-sizing: border-box">
            <div class="adminBox">
              <div
                class="item-box"
                style="
                  display: flex;
                  width: 24%;
                  min-height: 110px;
                  padding: 20px;
                "
                v-for="(item, i) in oldSumTable"
                :key="i"
              >
                <div class="box-top" style="flex: 2; flex-direction: column">
                  <p
                    class="title"
                    style="
                      font-weight: bold;
                      font-family: 微软雅黑;
                      font-size: 16px;
                      margin: 8px 0;
                    "
                  >
                    {{ item.title }}
                  </p>
                  <!-- <el-tag :type="item.type">{{ item.titleTag }}</el-tag> -->
                  <div
                    class="title"
                    style="
                      font-weight: bold;
                      font-family: 微软雅黑;
                      font-size: 34px;
                    "
                  >
                    {{ oldSumForm[item.data] || 0 }}
                    <!-- <br> -->
                  </div>
                </div>
                <div
                  class="contetn-box"
                  style="flex: 1"
                  v-if="MyEchartsTCManoyBool"
                >
                  <MyEchartsTCManoy
                    :TCmoneySum="TCmoneySum"
                    :itemColor="colorArr[i]"
                    :itemValue="oldSumForm[item.data] || 0"
                  >
                  </MyEchartsTCManoy>
                  <!-- <el-image style="width: 30px;height: 30px;" :src="item.src" fit="fill"></el-image> -->
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="titleBottom" v-if="activeName == 'dataT'">
        <div class="icoBox" @click="active = 2">
          <div class="adminBoxImg ico08"></div>
          <p>今日</p>
        </div>
        <div class="icoBox" @click="active = 1">
          <div class="adminBoxImg ico07"></div>
          <p>本月</p>
        </div>
      </div>

      <div
        id="chartColumn"
        v-if="activeName == 'dataT'"
        style="min-height: 400px; width: 100%"
      ></div>

      <div
        id="TCchartColumn"
        v-if="activeName == 'package'"
        style="min-height: 400px; width: 100%"
      ></div>

      <div
        id="TCMoneychartColumn"
        v-if="activeName == 'tcSum'"
        style="min-height: 400px; width: 60%"
      ></div>
    </div>
    <div
      v-else-if="id != 1 && realname != 'admin' && !dataForm.isAuthentication"
    >
      <!-- <div v-if="id != 1 && realname != 'admin' && dataForm.uuidPass_count != 0"> -->
      <div class="devMes">
        <a href="" class="one">
          <img src="../assets/img/money.png" alt="" />
          <div v-if="name != 'admin'">
            <p>{{ money }} 元</p>
            <i>账户余额</i>
          </div>
          <div v-else-if="name == 'admin'">
            <b>代理余额 {{ dl_money }}元</b><br />
            <b>企业余额 {{ qy_money }}元</b><br />
            <b>企业套餐 {{ qy_seatMoney }}元</b><br />
            <b>个人余额 {{ gr_seatMoney }}元</b>
          </div>

          <b></b>
        </a>
        <a href="/enterprise" class="two">
          <span>
            <img src="../assets/img/enterprise.png" alt="" />

            <p>{{ enterprice }} 个</p>
            <i>上线应用</i>
          </span>
        </a>
        <a href="/seats" class="three">
          <span>
            <img src="../assets/img/seatNum.png" alt="" />

            <p>{{ seatNum }} 个</p>
            <i>当前坐席</i>
            <b>签约坐席额度: {{ seatSumCount }}个</b>
          </span>
        </a>
      </div>
      <div class="cont">
        <div class="message1">
          <dl>
            <dt>
              账户余额：
              <em>{{ money }}</em>
              元
            </dt>
          </dl>
          <dl>
            <dt>
              当前费率:
              <em>{{ rate }}</em>
              元/分钟
            </dt>
          </dl>
          <dl>
            <dt>
              座席月租:
              <em>{{ seatRate }}</em>
              <label>元/月/座席</label>
            </dt>
          </dl>
          <dl>
            <dt>
              签约周期:
              <em>{{ cycle }}</em>
            </dt>
          </dl>
        </div>
        <div class="message1" style="margin-top: 20px">
          <dl>
            <dt>可用额度预警</dt>
            <dd style="font-size: 12px; margin-left: 30px; margin-top: 20px">
              可用额度预警: <i>(余额预警阈值为：￥ <em>100</em> )</i>
              <i
                style="
                  color: #1e90ff;
                  cursor: pointer;
                  font-style: normal;
                  margin-left: 50px;
                "
                @click="update()"
                >修改</i
              >
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- <div v-else>
          <div>
            欢迎您, {{ realname }} <br />还没进行代理商信息认证
            <el-button type="primary" @click="dialogVisible = true">立即认证<i
                class="el-icon-arrow-right el-icon--right"></i></el-button>
          </div>
        </div> -->
    <div class="authenticationCrad" v-else>
      <!-- <img src="../assets/img/qyrzImg.png" style="width: 100px;height: 100px;" alt="" srcset=""> -->
      <div class="authenticationMain">
        欢迎您, {{ realname }} <br /><span
          v-if="JSON.stringify(copyAddForm) != '{}'"
          >信息实名认证<span v-if="processStatus == 1">审核中</span
          ><span v-if="processStatus == 2">已被驳回</span>~</span
        ><span v-else>还没进行代理商信息实名认证~</span>
      </div>
      <el-button
        round
        size="small"
        type="primary"
        @click="openDialog"
        class="authenticationBtn"
        >立即认证<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
    </div>
    <!-- 套餐详情弹框 -->
    <el-dialog
      :title="`${seatTitle}套餐详情`"
      v-model="packageVisible"
      width="50%"
    >
      <el-table
        :data="packageArr"
        border
        header-cell-class-name="table-header"
        height="500"
      >
        <el-table-column prop="id" label="公司ID"></el-table-column>
        <el-table-column prop="user_id" label="代理ID"></el-table-column>
        <el-table-column
          prop="name"
          label="公司名称"
          width="280"
        ></el-table-column>
        <el-table-column prop="rate" label="费率"></el-table-column>
        <el-table-column prop="count" label="坐席数"></el-table-column>
      </el-table>
      <!-- <div style="text-align: right">
              <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
                @current-change="handlePageChange"></el-pagination>
            </div> -->
    </el-dialog>
    <!-- 测试报停企业弹框 -->
    <el-dialog
      :title="`测试/报停坐席详情`"
      v-model="test_stop_count_flag"
      width="50%"
    >
      <el-table
        :data="test_stop_count_form"
        border
        header-cell-class-name="table-header"
        height="500"
      >
        <el-table-column prop="id" label="公司ID"></el-table-column>
        <el-table-column prop="user_id" label="代理ID"></el-table-column>
        <el-table-column
          prop="name"
          label="公司名称"
          width="280"
        ></el-table-column>
        <el-table-column prop="rate" label="费率"></el-table-column>
        <el-table-column prop="count" label="坐席数"></el-table-column>
      </el-table>
      <!-- <div style="text-align: right">
              <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
                @current-change="handlePageChange"></el-pagination>
            </div> -->
    </el-dialog>
    <!-- <div class="moneyState">
                <h1>
                    <img src="../assets/img/money.png" alt=""/>
                    财务状态
                </h1>
                <a href="/enterprise">充值</a>
            </div> -->

    <el-dialog
      title="企业认证"
      v-model="dialogVisible"
      width="50%"
      @close="resetAddForm"
      :before-close="cleanImg"
      :close-on-click-modal="false"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        :rules="formRules"
        label-width="130px"
      >
        <!-- companyName: this.forms.companyName, //公司名称
                        legalName: this.forms.legalName, //法人姓名
                        legalCard: this.forms.legalCard, //法人身份证号
                        legalPhone: this.forms.legalPhone, //法人手机号
                        businessNumber: this.forms.businessNumber, //营业执照号
                        talk: this.forms.talk, //话术
                        channel: this.forms.channel, //报备通道
                        -->
        <el-form-item label="企业名称：" prop="companyName">
          <el-input v-model="addForm.companyName" disabled></el-input>
        </el-form-item>
        <el-form-item label="法人姓名：" prop="legalName">
          <el-input v-model="addForm.legalName"></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号：" prop="legalCard">
          <el-input v-model="addForm.legalCard"></el-input>
        </el-form-item>
        <el-form-item label="法人电话号：" prop="legalPhone">
          <el-input v-model="addForm.legalPhone"></el-input>
        </el-form-item>
        <el-form-item label="营业执照号：" prop="businessNumber">
          <el-input v-model="addForm.businessNumber"></el-input>
        </el-form-item>

        <el-form-item label="审核备注：" v-if="rejectRemark">
          <p style="color: red">{{ rejectRemark }}</p>
        </el-form-item>

        <!-- <el-form-item label="话术：" prop="talk">
                  <el-input type="textarea" class="textareaMinHeight" v-model="addForm.talk" disabled placeholder="请输入话术，字数限制:500字"
                    show-word-limit size="mini" maxlength="500"></el-input>
                </el-form-item>
                <el-form-item label="名片：">
                  <el-input v-model="addForm.channel" maxlength="500" placeholder="请输入报网站/公众号/小程序链接"></el-input>
                </el-form-item> -->
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="next">下一步</el-button>
          <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="informationVisible"
      width="60%"
      @closed=""
      :close-on-click-modal="false"
    >
      <template #title>
        <span class="informationClass">
          上传资料
          <span style="font-size: 14px">(只能上传jpg/png文件)</span>
        </span>
      </template>
      <!--businessImg: this.dataUrl, //营业执照复印件（只可上传一张）
              legalImg: this.dataUrl1, //法人身份证复印件1
              legalHandImg: this.dataUrl4, //法人身份证复印件2
              legalHand2Img: this.dataUrl6, //法人身份证复印件3
              agencyImg: this.dataUrl2, //代理人身份证复印件1/ 经办人身份证复印件（盖公章）1
              agencyHandImg: this.dataUrl5, //代理人身份证复印件2 / 经办人身份证复印件（盖公章）2
              talkImg: this.dataUrl3, //公司报备话术模板复印件 / 公司报备话术模板证复印件(盖公章)
              agencyHand2Img: this.dataUrl7, //公司报备话术模板证复印件(盖公章) 第四排第二张
              talk2Img: this.dataUrl8,//公司报备话术模板证复印件(盖公章) 第四排第三张 -->
      <div class="informationBox" v-if="dialogVisible">
        <!-- 营业执照复印件 -->
        <div class="informationTitle">
          <span class="redCle">*</span>
          <span class="mainText">营业执照复印件(盖公章) [限1个]</span>
        </div>
        <div style="margin-left: 16px">
          <el-upload
            ref="updataFile"
            :action="doUpload"
            :headers="myHeaders"
            :class="{ noneUpdataBox: addForm.businessImg }"
            list-type="picture-card"
            :on-preview="amplify"
            :on-remove="(res) => removeImg(res, 'businessImg')"
            :file-list="fileList.businessImg"
            :on-success="(res, res2) => updataSuccess(res, res2, 'businessImg')"
            accept=".png,.jpg"
            limit="1"
          >
            <i slot="default" class="el-icon-plus"> </i>
          </el-upload>
        </div>

        <!-- 法人身份证复印件 -->
        <div class="informationTitle">
          <span class="redCle">*</span>
          <span class="mainText">法人身份证复印件(正反面各一张)</span>
        </div>
        <div class="imgList">
          <el-upload
            ref="updataFile"
            :action="doUpload"
            :headers="myHeaders"
            :class="{ noneUpdataBox: addForm.legalImg }"
            list-type="picture-card"
            :on-preview="amplify"
            :on-remove="(res) => removeImg(res, 'legalImg')"
            :file-list="fileList.legalImg"
            :on-success="(res, res2) => updataSuccess(res, res2, 'legalImg')"
            accept=".png,.jpg"
            limit="1"
          >
            <i slot="default" class="el-icon-plus"> </i>
          </el-upload>
          <el-upload
            ref="updataFile"
            :action="doUpload"
            :headers="myHeaders"
            :class="{ noneUpdataBox: addForm.legalHandImg }"
            list-type="picture-card"
            :on-preview="amplify"
            :on-remove="(res) => removeImg(res, 'legalHandImg')"
            :file-list="fileList.legalHandImg"
            :on-success="
              (res, res2) => updataSuccess(res, res2, 'legalHandImg')
            "
            accept=".png,.jpg"
            limit="1"
          >
            <i slot="default" class="el-icon-plus"> </i>
          </el-upload>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="informationVisible = false">返回上一步</el-button>
          <el-button type="primary" @click="submitInformation"
            >确定认证</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 放大图片 -->
    <el-dialog v-model="amplifyVisible" @close="amplifyImageUrl = ''">
      <img style="width: 100%" :src="amplifyImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Schart from "vue-schart";
import * as echarts from "echarts";
import MyEcharts from "@/views/views_components/MyEcharts.vue";
import MyEchartsTCManoy from "@/views/views_components/MyEchartsTCManoy.vue";

// let token = localStorage.getItem("TOKEN");

export default {
  name: "dashboard",
  components: {
    Schart,
    MyEcharts,
    MyEchartsTCManoy,
  },
  data() {
    return {
      test_stop_count_form: [],
      test_stop_count_flag: false,
      rejectRemark: null,
      myHeaders: {
        "X-Access-Token": "",
      },
      fileList: {
        businessImg: [],
        legalImg: [],
        legalHandImg: [],
      },
      doUpload: "api/uuid-pass/upload",
      amplifyImageUrl: "",
      amplifyVisible: false,
      informationVisible: false,
      formRules: {
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        legalName: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        legalCard: [
          { required: true, message: "请输入法人身份证号", trigger: "blur" },
        ],
        legalPhone: [
          { required: true, message: "请输入法人电话号", trigger: "blur" },
        ],
        businessNumber: [
          { required: true, message: "请输入营业执照号", trigger: "blur" },
        ],
      },
      addForm: {},
      processStatus: 3,
      dialogVisible: false,
      MyEchartsTCManoyBool: false,
      colorArr: ["#3162F4", "#33CC66", "#FF9933", "#FF8080"],
      identifyCode: {},
      oldSumForm: {},
      seatTitle: null,
      packageVisible: false,
      packageArr: [],
      seatRateArr: [],
      activeName: "dataT",
      lineForm: {},
      lineForm2: {},
      copyAddForm: {},
      active: 1,
      tabPosition: "left",
      chartColumn: null,
      dataForm: {
        isAuthentication: false,
      },
      rateSrc: require(`@/assets/miniImg/taocan01.png`),
      sqcur: require(`@/assets/miniImg/sqcur.png`),
      adminList: [
        {
          title: "代理余额",
          titleTag: "￥",
          className: "ico01",
          src: require(`@/assets/miniImg/daili.png`),
          data: "dl_money",
          type: "",
        },
        {
          title: "企业余额",
          titleTag: "￥",
          className: "ico02",
          src: require(`@/assets/miniImg/qiye.png`),
          data: "qy_money",
          type: "",
        },
        // {
        //   title: '企业套餐',
        //   titleTag: '￥',
        //   src: require(`@/assets/miniImg/taocan.png`),
        //   data: 'qy_seatMoney',
        //   type: 'warning'
        // },
        {
          title: "个人套餐余额",
          titleTag: "￥",
          className: "ico03",
          src: require(`@/assets/miniImg/yue.png`),
          data: "gr_seatMoney",
          type: "",
        },
        {
          title: "上线应用",
          titleTag: "个",
          className: "ico04",
          src: require(`@/assets/miniImg/yingyong.png`),
          data: "uuidCount",
          type: "",
        },
        {
          title: "当前坐席",
          titleTag: "位",
          className: "ico05",
          src: require(`@/assets/miniImg/zuoxi.png`),
          data: "seatCount",
          type: "success",
        },
      ],
      oldSumTable: [
        {
          title: "上月创建坐席金额",
          titleTag: "￥",
          src: require(`@/assets/miniImg/zuoxi-yue.png`),
          data: "sum",
          type: "",
        },
        {
          title: "上月创建坐席金额-负企业",
          titleTag: "￥",
          src: require(`@/assets/miniImg/yue-fu.png`),
          data: "sum_noMoney",
          type: "danger",
        },

        {
          title: "上月套餐外消费金额",
          titleTag: "￥",
          src: require(`@/assets/miniImg/taocanwai.png`),
          data: "sum_seatWai",
          type: "",
        },
        {
          title: "上月退还套餐金额",
          titleTag: "￥",
          src: require(`@/assets/miniImg/taocanwai.png`),
          data: "sum_returnSeatMoney",
          type: "",
        },
      ],
      seatRateSum: 0,
      TCmoneySum: 0,
      lineTable: [
        // {
        //   title: '联通3线路',
        //   titleTag: '分钟',
        //   src: require(`@/assets/miniImg/sxianlu.png`),
        //   data: '联通3线路',
        //   type: 'success'
        // },
        //
        {
          title: "电信线路",
          titleTag: "分钟",
          src: require(`@/assets/miniImg/2haoxian.png`),
          data: "电信线路",
          type: "success",
        },
        {
          title: "X线路（常州）",
          titleTag: "分钟",
          src: require(`@/assets/miniImg/diqu.png`),
          data: "X线路",
          type: "success",
        },
        {
          title: "连云港线路",
          titleTag: "分钟",
          src: require(`@/assets/miniImg/yidong1.png`),
          data: "连云港线路",
          type: "success",
        },
        {
          title: "甘肃线路",
          titleTag: "分钟",
          src: require(`@/assets/miniImg/yidong1.png`),
          data: "甘肃线路",
          type: "success",
        },

        {
          title: "3号线线路",
          titleTag: "分钟",
          src: require(`@/assets/miniImg/diqu.png`),
          data: "3号线线路",
          type: "success",
        },
        {
          title: "2号线线路",
          titleTag: "分钟",
          src: require(`@/assets/miniImg/2haoxian.png`),
          data: "2号线线路",
          type: "success",
        },


        // , {
        //   title: '3号线线路(青海)',
        //   titleTag: '分钟',
        //   src: require(`@/assets/miniImg/3haoxian.png`),
        //   data: '3号线线路',
        //   type: 'success'
        // },  {
        //   title: '1号线线路',
        //   titleTag: '分钟',
        //   src: require(`@/assets/miniImg/1haoxian.png`),
        //   data: '1号线线路',
        //   type: 'success'
        // }
      ],
      dl_money: 0, //
      qy_money: 0, //
      qy_seatMoney: 0, //
      gr_seatMoney: 0, //
      money: 0, //用户余额
      enterprice: 0, //企业数量
      seatNum: 0, //坐席数量
      seatSumCount: 0, //签约坐席额度
      rate: 0, //费率
      seatRate: 0, //月租
      cycle: "",
      realname: "",
      name: localStorage.getItem("realname"),
      id: null,
      chatrArr: [],
      tcsumBool: false,
      TCstatistBool: false,
    };
  },

  computed: {
    role() {
      return this.name === "admin" ? "超级管理员" : "普通用户";
    },
  },

  watch: {
    //参数1：改变后的值
    //参数2：改变前的值
    active(newValue, oldValue) {
      //  // console.log("newValue", newValue);
      //  // console.log("oldValue", oldValue);
      if (oldValue == 2) {
        this.lineForm = this.dataForm.month_minute;
      } else {
        this.lineForm = this.dataForm.day_minute;
      }
      this.lineForm2 = this.dataForm.top_userMoney;
      this.chatrArr = [];
      Object.keys(this.lineForm).forEach((key) => {
        if (this.lineForm[key]) {
          this.chatrArr.push({ title: key, value: this.lineForm[key] });
        } else {
          this.chatrArr.push({ title: key, value: 0 });
        }
      });

      // console.log("lineForm", this.lineForm);

      this.drawColumnChart();
    },
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.id = localStorage.getItem("id");
    this.money = localStorage.getItem("money");
    this.$nextTick(() => {
      this.getList();
      //   setTimeout(() => {
      //     this.drawColumnChart()
      //   }, 500)
      window.onload = function () {
        console.log("控制浏览器缩放80%");
        // 控制浏览器缩放
        document.body.style.zoom = "80%";
      };
    });
  },
  mounted() {
    let token = localStorage.getItem("TOKEN");
    this.myHeaders = {
      "X-Access-Token": token,
    };
    // console.log("myHeaders",this.myHeaders['X-Access-Token']);
    // console.log("token",token);
    if (this.id == 1) {
      setTimeout(() => {
        this.drawColumnChart();
      }, 1000);
    }
  },
  methods: {
    //查询报停测试坐席个数明细
    test_stop_count() {
      axios
        .post("api/seat-phone/findStopAndTestCount-group")
        .then((res) => {
          if ((res.data.statusCode = "00000")) {
            this.test_stop_count_form = res.data.data;
          } else {
            // this.$message.error(res.data.message || '删除失败！');
          }
        })
        .catch((err) => {
          // this.$message.error("删除失败！");
        });
      this.test_stop_count_flag = true;
    },
    openDialog() {
      this.addForm = JSON.parse(JSON.stringify(this.copyAddForm));
      this.dialogVisible = true;
      this.addForm.talk = "实名认证";
      this.addForm.companyName = this.realname;
      this.fileList = {
        businessImg: this.addForm.businessImg
          ? [{ url: this.addForm.businessImg }]
          : [],
        legalImg: this.addForm.legalImg ? [{ url: this.addForm.legalImg }] : [],
        legalHandImg: this.addForm.legalHandImg
          ? [{ url: this.addForm.legalHandImg }]
          : [],
      };
      // console.log("this.fileList", this.fileList);
    },
    // 关闭企业认证弹框时删除图片并清空表单
    async resetAddForm() {
      this.addForm = {};
      this.fileList = {
        businessImg: [],
        legalImg: [],
        legalHandImg: [],
      };
      this.rejectRemark = null;
      await this.getList();
    },
    // 点击X关闭弹框事件
    async cleanImg(done) {
      // let arr = ["businessImg", "legalImg", "legalHandImg"];
      // await arr.forEach((v) => {
      //   if (this.addForm[v]) {
      //     let params = {
      //       url: this.addForm[v],
      //     };
      //     axios
      //       .post("api/uuid-pass/del-img", params)
      //       .then((res) => {
      //         if ((res.data.statusCode = "00000")) {
      //         } else {
      //           // this.$message.error(res.data.message || '删除失败！');
      //         }
      //       })
      //       .catch((err) => {
      //         // this.$message.error("删除失败！");
      //       });
      //   }
      // });
      done();
    },
    // 确定认证
    submitInformation() {
      if (
        !(
          this.addForm.businessImg &&
          this.addForm.legalImg &&
          this.addForm.legalHandImg
        )
      ) {
        this.$alert("请上传完整资料", "", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
        return false;
      } else {
        // console.log(this.addForm.businessImg, this.addForm.legalImg, this.addForm.legalHandImg);
        // console.log('开始认证');
        let params = {
          ...this.addForm,
          status: 1,
        };
        let salesman = localStorage.getItem("salesman");
        if (salesman) params.salesman = salesman;

        if (!this.addForm.id) {
          axios
            .post("api/uuid-pass/add", params)
            .then((res) => {
              if ((res.data.statusCode = "00000")) {
                this.$message.success("已提交认证");
                // this.getList()
                this.dialogVisible = false;
                this.informationVisible = false;
                // location.reload();
              } else {
                this.$alert(res.data.message, "", {
                  confirmButtonText: "确定",
                  callback: (action) => {},
                });
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        } else {
          axios
            .post("api/uuid-pass/update", params)
            .then((res) => {
              if ((res.data.statusCode = "00000")) {
                this.$message.success("已提交认证");
                // this.getList()
                this.dialogVisible = false;
                this.informationVisible = false;
                // location.reload();
              } else {
                this.$alert(res.data.message, "", {
                  confirmButtonText: "确定",
                  callback: (action) => {},
                });
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        }
      }
    },
    updataSuccess(response, file, type) {
      // console.log("成功时的文件", file);
      // console.log("成功时的文件参数名", type);
      // console.log("成功时返回数据", response);
      if (response.statusCode == "00000") {
        this.addForm[type] = response.data;
      } else {
        this.$refs.updataFile.handleRemove(file);
        this.$message.error(response.message || "上传失败");
      }
    },
    amplify(file) {
      // console.log("放大的文件", file.response.data);
      if (file.response.statusCode == "00000") {
        this.amplifyImageUrl = file.response.data;
        this.amplifyVisible = true;
      } else {
        this.$refs.updataFile.handleRemove(file);
        this.$message.error("该文件不存在");
      }
    },
    removeImg(file, type) {
      // console.log("删除的文件", file);
      // console.log("删除的文件类型", type);
      if (this.addForm[type]) delete this.addForm[type];
      if (this.addForm.id) this.removeImgAndSubmit(type);
      // console.log("file",file);
      if (file.status == "success") {
        let params = {
          url: file.response ? file.response.data : file.url,
        };
        axios
          .post("api/uuid-pass/del-img", params)
          .then((res) => {
            if ((res.data.statusCode = "00000")) {
            } else {
              this.$message.error(res.data.message || "删除失败！");
            }
          })
          .catch((err) => {
            this.$message.error("删除失败！");
          });
      } else {
        this.$refs.updataFile.handleRemove(file);
        this.$message.error("该文件不存在");
      }
    },
    removeImgAndSubmit(type) {
      let params = {
        id: this.addForm.id,
        legalImg: this.addForm.legalImg,
        legalHandImg: this.addForm.legalHandImg,
      };
      params[type] = "null";
      // console.log("参数",params);
      axios
        .post("api/uuid-pass/update", params)
        .then((res) => {
          if ((res.data.statusCode = "00000")) {
            // this.getList()
          } else {
            // this.$alert(res.data.message, "", {
            //   confirmButtonText: "确定",
            //   callback: (action) => { },
            // });
          }
        })
        .catch((err) => {
          // this.$message.error(err.data.message);
        });
    },
    next() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.informationVisible = true;
        } else {
          this.$message.error("请检查表单输入是否正确");
          return false;
        }
      });
    },
    getOldSumSeatRate() {
      if (this.tcsumBool) return false;
      axios
        .post("api/seat-phone/oldSum-seat-rate", {})
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.tcsumBool = true;
            this.oldSumForm = res.data.data;
            this.TCmoneySum = 0;
            for (let key in this.oldSumForm) {
              this.oldSumForm[key] = this.oldSumForm[key] * 1 * 1;
              let num = this.oldSumForm[key] * 1;
              this.TCmoneySum += num;
            }
            console.log("显示666", this.TCmoneySum);
            this.MyEchartsTCManoyBool = true;
          } else {
            this.$message.error(res.data.Message);
          }
        })
        .catch((err) => {
          // console.log("err", err);
        });
    },
    openPackageDetails(value) {
      this.packageVisible = true;
      axios
        .post("api/seat-phone/seat-rate-count", { seatRate: value.seat_rate })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.statusCode == "00000") {
            this.packageArr = res.data.data;
            this.seatTitle = value.seat_rate;
            //  // console.log("显示");
          } else {
            this.$message.error(res.data.Message);
          }
        })
        .catch((err) => {
          // console.log("err", err);
        });
    },
    async handleClick(tab, event) {
      //  // console.log('tab', tab.paneName);
      if (tab.paneName == "package") {
        this.getTCstatistics();
      } else if (tab.paneName == "tcSum") {
        await this.getOldSumSeatRate();
      }
      // this.chartColumn.clear(); // 清空图表

      if (tab.paneName == "dataT") {
        setTimeout(() => {
          // this.chartColumn.clear(); // 清空图表
          this.drawColumnChart();
        }, 1000);
      } else if (tab.paneName == "package") {
        setTimeout(() => {
          // this.chartColumn.clear(); // 清空图表
          this.rateColumnChart();
        }, 1000);
      } else if (tab.paneName == "tcSum") {
        setTimeout(() => {
          // this.chartColumn.clear(); // 清空图表
          this.TCMoneyColumnChart();
        }, 1000);
      }
    },
    // echarts
    drawColumnChart() {
      // let sum = 0
      // this.chatrArr.forEach((v) => {
      //    // console.log("v", typeof v.value);
      //   if (typeof v.value === 'number') {
      //     sum += v.value
      //   }
      // })

      // this.dataForm.month_minute
      // this.dataForm.day_minute
      let month_minute = [];
      Object.keys(this.dataForm.month_minute).forEach((key) => {
        if (this.dataForm.month_minute[key]) {
          month_minute.push({
            title: key,
            value: this.dataForm.month_minute[key],
          });
        } else {
          month_minute.push({ title: key, value: 0 });
        }
      });

      let day_minute = [];
      Object.keys(this.dataForm.day_minute).forEach((key) => {
        if (this.dataForm.day_minute[key]) {
          day_minute.push({ title: key, value: this.dataForm.day_minute[key] });
        } else {
          day_minute.push({ title: key, value: 0 });
        }
      });

      // if (oldValue == 2) {
      //   this.lineForm = this.dataForm.month_minute
      // } else {
      //   this.lineForm = this.dataForm.day_minute
      // }
      // this.chatrArr = []
      // Object.keys(this.lineForm).forEach((key) => {
      //   if (this.lineForm[key]) {
      //     this.chatrArr.push({ title: key, value: this.lineForm[key] })
      //   } else {
      //     this.chatrArr.push({ title: key, value: 0 })
      //   }
      // })

      //  // console.log("标题", this.titleArr);
      //  // console.log("值", this.valueArr);
      let chartColumn = echarts.init(document.getElementById("chartColumn"));

      let colors = ["#46D269", "#1F6AE2"];
      chartColumn.setOption({
        color: colors,
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        title: {
          text: "分钟数",
        },
        // tooltip: {
        //   trigger: "item",
        //   formatter: "{b}  {c}分钟",
        // },

        // legend: {
        //   data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        // },
        // toolbox: {
        //   feature: {
        //     magicType: { show: true, type: ['line', 'bar', 'stack'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.chatrArr.map((v) => v.title),
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: "15",
                fontWeight: "bolder",
                color: "#1f1f1f",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "今日",
            type: "line",
            smooth: true, //变为曲线 默认false折线
            symbolSize: 1, //去掉页面上显示的圆点
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#35C956",
              },
              formatter: function (params) {
                return params.value + "分钟";
              },
            },
            itemStyle: {
              normal: {
                // color: "#F20D6F",
                lineStyle: {
                  color: "#35C956",
                  width: 3,
                },
              },
            },
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: day_minute.map((v) => v.value),
          },
          {
            name: "本月",
            type: "line",
            stack: "Total",
            smooth: true, //变为曲线 默认false折线
            symbolSize: 1, //去掉页面上显示的圆点
            areaStyle: {},
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#216FED",
              },
              formatter: function (params) {
                return params.value + "分钟";
              },
            },
            itemStyle: {
              normal: {
                // color: "#F20D6F",
                lineStyle: {
                  color: "#216FED",
                  width: 3,
                },
              },
            },
            emphasis: {
              focus: "series",
            },
            data: month_minute.map((v) => v.value),
          },
        ],
      });
    },
    rateColumnChart() {
      let sum = 0;
      this.seatRateSum = 0;
      this.seatRateArr.forEach((v) => {
        sum += v.count;
        this.seatRateSum += v.count;
      });
      let chartColumn = echarts.init(document.getElementById("TCchartColumn"));
      chartColumn.setOption({
        title: [
          {
            text: "套餐统计",
            subtext: "总计 " + sum + "个",
            left: "50%",
            textAlign: "center",
          },
        ],
        // tooltip: {
        //   show: true,
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'cross',
        //     label: {
        //       backgroundColor: '#6a7985'
        //     }
        //   }
        // },
        // tooltip: {
        //   show: true,
        //   trigger: "item",
        //   formatter: "{b}  {c}分钟",
        // },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        xAxis: {
          type: "category",
          name: "套餐名称",
          data: this.seatRateArr.map((v) => {
            return v.seat_rate + "套餐";
          }),
        },
        yAxis: {
          type: "value",
          name: "套餐开通数/个",
        },
        series: [
          {
            data: this.seatRateArr.map((v) => {
              return v.count;
            }),
            type: "line",
            symbol: "triangle",
            symbolSize: 20,
            lineStyle: {
              color: "#5470C6",
              width: 3,
              type: "dashed",
            },
            itemStyle: {
              borderWidth: 5,
              borderColor: "#0066CC",
              color: "#D6EBFF",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#3278FE",
              },
              formatter: function (params) {
                return params.value + "个";
              },
            },
          },
        ],
      });
    },
    TCMoneyColumnChart() {
      let chartColumn = echarts.init(
        document.getElementById("TCMoneychartColumn")
      );
      chartColumn.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        color: ["#3162F4", "#33CC66", "#FF9933", "#FF8080"],
        legend: {
          type: "scroll",
          orient: "vertical",
          data: [
            "上月创建坐席金额",
            "上月创建坐席金额-负企业",
            "上月套餐外消费金额",
            "上月退还套餐金额",
          ],

          right: 0,
          top: "center",
        },
        series: [
          {
            name: "套餐金额统计图",
            type: "pie",
            roseType: "radius",
            selectedMode: "single",
            radius: ["40%", "70%"],

            label: { show: false },
            data: [
              {
                value: this.oldSumForm["sum"],
                name: "上月创建坐席金额",
                selected: true,
              },
              {
                value: this.oldSumForm["sum_noMoney"],
                name: "上月创建坐席金额-负企业",
              },
              {
                value: this.oldSumForm["sum_seatWai"],
                name: "上月套餐外消费金额",
              },
              {
                value: this.oldSumForm["sum_returnSeatMoney"],
                name: "上月退还套餐金额",
              },
            ],
          },
          {
            name: "套餐金额统计图",
            type: "pie",
            // color:['#EAEAEA','#CECECE','#B9B9B9'],
            radius: ["10%", "24%"],
            label: {
              show: false,
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 4,
              color: function (colors) {
                var colorList = ["#EAEAEA", "#CECECE", "#B9B9B9"];
                return colorList[colors.dataIndex];
              },
            },
            data: [
              { value: this.oldSumForm["sum"], name: "上月创建坐席金额" },
              {
                value: this.oldSumForm["sum_noMoney"],
                name: "上月创建坐席金额-负企业",
              },
              {
                value: this.oldSumForm["sum_seatWai"],
                name: "上月套餐外消费金额",
              },
              {
                value: this.oldSumForm["sum_returnSeatMoney"],
                name: "上月退还套餐金额",
              },
            ],
          },
        ],
      });
    },
    //余额、企业数量、坐席数量
    getList() {
      let lastIp = sessionStorage.getItem("ip");
      let params = {
        ip: lastIp,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/getUserLogin", params)
        .post("api/user/getUserLogin", params)
        .then((res) => {
          // console.log("登录参数",res)
          //  // console.log("res.data",res.data);
          if (res.data.statusCode == "00000") {
            this.processStatus = 3;
            this.dataForm = res.data.data;
            if (this.realname == "admin") {
              this.dataForm.isAuthentication = false; // 已认证
            } else if (this.dataForm.uuidPassList.length == 0) {
              this.dataForm.isAuthentication = true; // 未认证
            } else if (this.dataForm.uuidPassList.length >= 2) {
              this.dataForm.isAuthentication = false; // 已认证
            } else if (this.dataForm.uuidPassList.length == 1) {
              if (this.dataForm.uuidPassList[0].status == 3) {
                this.dataForm.isAuthentication = false; // 已认证
              } else {
                this.dataForm.isAuthentication = true; // 未认证/未通过
                this.processStatus = this.dataForm.uuidPassList[0].status;
                let data = this.dataForm.uuidPassList[0];
                let {
                  id,
                  companyName,
                  legalName,
                  legalCard,
                  legalPhone,
                  businessNumber,
                } = data;
                this.copyAddForm = {
                  id,
                  companyName,
                  legalName,
                  legalCard,
                  legalPhone,
                  businessNumber,
                };

                if (data.businessImg != null && data.businessImg != "null") {
                  this.copyAddForm.businessImg = data.businessImg;
                }
                let legalImgArr = data.legalImg.split(",");
                legalImgArr[0] != "null"
                  ? (this.copyAddForm.legalImg = legalImgArr[0])
                  : "";
                legalImgArr[1] != "null"
                  ? (this.copyAddForm.legalHandImg = legalImgArr[1])
                  : "";
                this.rejectRemark = this.dataForm.uuidPassList[0].remark;
              }
            } else {
              this.dataForm.isAuthentication = false; // 否则默认已认证，隐藏弹框
            }
            localStorage.setItem("dataForm", JSON.stringify(this.dataForm));
            this.$emit("getsidebarRef", "父组件");

            this.lineForm = this.dataForm.month_minute;
            this.lineForm2 = this.dataForm.top_userMoney;

            this.money = res.data.data.money || 0;
            this.enterprice = res.data.data.uuidCount || 0;
            this.seatNum = res.data.data.seatCount || 0;
            this.seatSumCount = res.data.data.seatSumCount || 0;
            this.rate = res.data.data.rate || 0;
            this.dl_money = res.data.data.dl_money || 0;
            this.qy_money = res.data.data.qy_money || 0;
            this.qy_seatMoney = res.data.data.qy_seatMoney || 0;
            this.seatCount_stop = res.data.data.seatCount_stop || 0;
            this.gr_seatMoney = res.data.data.gr_seatMoney || 0;
            if (res.data.data.ip)
              localStorage.setItem("province", res.data.data.ip.ip);
            if (this.id == 1) {
              Object.keys(this.lineForm).forEach((key) => {
                if (this.lineForm[key]) {
                  this.chatrArr.push({ title: key, value: this.lineForm[key] });
                } else {
                  this.chatrArr.push({ title: key, value: 0 });
                }
              });
              Object.keys(this.lineForm2).forEach((key) => {
                if (this.lineForm2[key]) {
                  this.chatrArr.push({
                    title: key,
                    value: this.lineForm2[key],
                  });
                } else {
                  this.chatrArr.push({ title: key, value: 0 });
                }
              });
            }
          }

          if (res.data.data.cycle == null) {
            this.cycle = "";
          } else {
            this.cycle = res.data.data.cycle;
          }

          if (null != res.data.data.ip) {
            localStorage.setItem("province", res.data.data.ip.ip);
            localStorage.setItem("city", res.data.data.ip.city);
          } else {
            localStorage.setItem("province", "");
            localStorage.setItem("city", "");
          }
        })
        .catch((err) => {
          // console.log(err.message);
        });
    },
    getTCstatistics() {
      if (this.TCstatistBool) return false;
      if (this.id == 1) {
        axios
          .post("api/seat-phone/seat-rate-count", {})
          .then((res) => {
            if (res.data.statusCode == "00000") {
              this.TCstatistBool = true;
              this.seatRateArr = res.data.data;
              this.seatRateArr.forEach((v) => {
                this.seatRateSum += v.count;
              });
            }
          })
          .catch((err) => {
            // console.log(err.message);
          });
      }
    },
    changeDate() {
      const now = new Date().getTime();
      this.data.forEach((item, index) => {
        const date = new Date(now - (6 - index) * 86400000);
        item.name = `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()}`;
      });
    },
    api() {
      window.open(this.indexApi, "_blank");
    },
    topApi() {
      window.open(this.topindexApi, "_blank");
    },
    topApi1() {
      window.open(this.topindexApi1, "_blank");
    },
  },
  //修改
  update() {
    // console.log("修改的方法");
  },
};
</script>

<style scoped lang="scss">
.imgList {
  margin-left: 16px;
  display: flex;

  & > div {
    margin-right: 10px;
  }
}

::v-deep .noneUpdataBox {
  .el-upload--picture-card {
    display: none !important;
  }
}

.informationClass {
  font-size: 24px;
  font-family: 微软雅黑;
  font-weight: bold;
}

.informationBox {
  max-height: 600px;
  overflow-y: auto;
}

.informationTitle {
  margin: 16px 0;

  .redCle {
    font-weight: bold;
    font-size: 18px;
    color: red;
  }

  .mainText {
    margin-left: 2px;
    font-weight: bold;
    font-size: 18px;
  }
}

::v-deep.textareaMinHeight .el-textarea__inner {
  min-height: 120px !important;
}

.authenticationCrad {
  width: 900px;
  height: 400px;
  // border: 1px solid red;
  // margin: auto;
  margin-top: 3%;
  margin-left: 3%;
  // background-color: #F3F3F3;
  border-radius: 25px;
  padding: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.22);
  background-image: url("../assets/img/qyrzImg.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: 260px 0;
  // margin-right: 200px;
}

.authenticationMain {
  // width: 300px;
  font-size: 20px;
  color: #8d7a7f;
  line-height: 50px;
}

.authenticationBtn {
  margin-top: 60px;
  font-size: 16px;
  width: 140px;

  i {
    font-weight: bold;
    // font-size: 12px;
    color: #409eff;
    background-color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
}

::v-deep .tabTop {
  .el-tabs__nav-wrap::after,
  .el-tabs__active-bar {
    display: none;
  }

  .el-tabs__item {
    padding: 0 7px !important;
  }

  .el-tabs__header {
    margin: 0 0 4px !important;
  }
}

.adminBox {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.adminBox > .rollBox {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.adminBox > .rollBox .item-box {
  //   width: 400px;
  width: 17%;
  margin: 0 1.8%;
  display: inline-block;
}

.adminBox > .rollBox .item-box:first-child {
  margin-left: 0;
}

.adminBox > .rollBox .item-box:last-child {
  margin-right: 0;
}

.titleBottom {
  display: flex;
  flex-direction: row-reverse;

  .icoBox {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  .icoBorder {
    color: #3395ff;
  }
}

.ico07 {
  background-position: -122px -95px;
}

.ico08 {
  background-position: -173px -97px;
}

.adminBoxImg {
  width: 30px;
  height: 30px;
  background-image: url("../assets/miniImg/sqcur.png");
  background-size: 400px;
  font-family: 微软雅黑;
}
.adminBox .item-box {
  width: 17.6%;
}

.adminBox .item-box {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding: 10px 20px 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  cursor: pointer;

  .ico01 {
    background-position: -385px -30px;
    background-size: 360px;
  }

  .ico02 {
    background-position: -480px -37px;
  }

  .ico03 {
    background-position: -536px -37px;
  }

  .ico04 {
    background-position: -590px -35px;
  }

  .ico05 {
    background-position: -660px -37px;
  }

  .ico06 {
    background-position: -56px -99px;
  }
}

.adminBox > .item-box > .box-top {
  display: flex;
  // justify-content: space-between;
  align-content: center;
  // padding-bottom: 4px;
  box-sizing: border-box;
  font-family: EU-YT2;
  // border-bottom: 1px solid #ECEFF5;
}

.item-box > .box-top > .title {
  display: flex;
  align-items: center;
  // font-weight: bold;
}

.adminBox > .item-box > .contetn-box {
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.item-box > .contetn-box .annex {
  font-size: 10px;
  // color: #f2ae43;
  margin-top: 6px;
  font-weight: normal;
  font-family: 黑体;
}

.yarrowColor {
  color: #f2ae43;
}

.item-box > .contetn-box > .title {
  display: flex;
  // align-items: center;
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  min-height: 54px;

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
}

.content {
  padding: 0 20px;
  background-color: #ffff;
  overflow-y: scroll;
  height: 100%;
}

.talk a {
  color: black;
}

.talk a:link {
  margin-left: 20px;
  text-decoration: none;
  color: #900b09;
  background: transparent;
  border-bottom: 1px solid #900b09;
}

.talk a:hover {
  text-decoration: none;
  color: #ff0000;
  background: transparent;
  border-bottom: 1px solid #ff0000;
}

.tit {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tit h1 {
  font-family: "兰亭纤黑";
  font-size: 30px;
  font-weight: normal;
  height: 48px;
  line-height: 48px;
  width: 30%;
}

.dep span em {
  /* color: #28b740; */
}

.devMes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.devMes img {
  position: relative;
  top: 10px;
  left: 25px;
}

.one {
  /* height: 120px; */
  margin: 5px;
  box-sizing: border-box;
  /* width: 320px; */
  flex: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.one p {
  position: relative;
  top: -40px;
  left: 160px;
  font-size: 30px;
  color: #ffa022;
}

.one i {
  position: relative;
  top: -15px;
  left: 20px;
  color: #ffa022;
  font-style: normal;
}

.one b {
  position: relative;
  top: -17px;
  left: 115px;
  color: #ffa022;
  font-style: normal;
  font-size: 12px;
}

.two {
  margin: 5px;
  box-sizing: border-box;
  /* width: 320px; */
  flex: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.two p {
  position: relative;
  top: -40px;
  left: 160px;
  font-size: 30px;
  color: #3395ff;
}

.two i {
  position: relative;
  top: -15px;
  left: 20px;
  color: #3395ff;
  font-style: normal;
}

.three {
  margin: 5px;
  box-sizing: border-box;
  /* width: 320px; */
  flex: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.three p {
  position: relative;
  top: -40px;
  left: 160px;
  font-size: 30px;
  color: #fe4c56;
  width: 200px;
}

.three i {
  position: relative;
  top: -15px;
  left: 20px;
  color: #fe4c56;
  font-style: normal;
}

.three b {
  position: relative;
  top: -17px;
  left: 115px;
  color: #fe4c56;
  font-style: normal;
  font-size: 12px;
}

.down {
  width: 100%;
  background-color: #666;
  height: 2px;
  margin-bottom: 12px;
}

.devMainnum {
  padding: 50px;
}

.devMainnum h1 {
  color: #808080;
  font-size: 24px;
  font-weight: normal;
  height: 69px;
  line-height: 69px;
  padding: 0 0 0 80px;
  width: 210px;
}

.moneyState {
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.moneyState h1 {
  color: #808080;
  font-size: 24px;
  font-weight: normal;
  height: 69px;
  line-height: 69px;
  padding: 0 0 0 30px;
  width: 210px;
  display: flex;
  justify-content: space-around;
}

.moneyState a {
  background: #f2ae43 none repeat scroll 0 0;
  height: 46px;
  width: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .message{
            background: #f4fafd none repeat scroll 0 0;
            margin-right: 26px;
            padding: 30px 0 18px;
            width: 68%;
        }
        .message dl {
            color: #565656;
            font-size: 16px;
            padding: 0 0 12px;
            width: 100%;
        }
        .message dt {
            float: left;
            height: 26px;
            line-height: 26px;
            padding-right: 20px;
            text-align: right;
            width: 220px;
        }
        .message dd {
            font-size: 14px;
            height: 26px;
            line-height: 26px;
        } */
.message1 {
  background: #f4fafd none repeat scroll 0 0;
  margin: 10px 0 0;
  overflow: hidden;
  padding: 30px 0 18px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.message1 dl {
  margin-top: 10px;
  margin-left: 30px;
}

.message1 em {
  color: #f49500;
}

.message2 {
  background: #f4fafd none repeat scroll 0 0;
  margin: 10px 0 0;
  overflow: hidden;
  padding: 30px 0 18px;
  width: 100%;
}

.graytips {
  color: #9c9c9c;
  padding-left: 20px;
}

.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
  height: 200px;
  width: 200px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

/* .two{
            background-color: rgb(97 157 183);
            border-radius: 30px;
        } */
.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
