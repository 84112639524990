<template>
    <div class="echarts">
        <div id="" style="width: 100%; height: 120px" ref="echartsTC"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: 'MyEcharts',
    props: ["TCmoneySum", 'itemValue', 'itemColor'],
    mounted() {
         // console.log("this.TCmoneySum", this.TCmoneySum);
         // console.log("this.itemValue", this.itemValue);

        let chartColumn = echarts.init(this.$refs.echartsTC);
        chartColumn.setOption(
            {
                // tooltip: {
                //     trigger: 'item'
                // },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        color: [this.itemColor, '#EBEBEB'],
                        radius: ['30%', '80%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            // borderRadius: 10,
                            borderColor: '#fff',
                            // borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        // emphasis: {
                        //     label: {
                        //         show: true,
                        //         fontSize: 40,
                        //         fontWeight: 'bold'
                        //     }
                        // },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.TCmoneySum, name: '总个数' },
                            { value: this.itemValue, name: '个数' },
                        ]
                    }
                ]
            }
        )
    },
    methods: {

    }
}
</script>


<style scoped lang="scss"></style>
